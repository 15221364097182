import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { PageResponse } from '@core/shared/domain';
import { QueryParams } from '@core/shared/util';
import {
  PlatformSupplier,
  PlatformSupplierBasic,
  PlatformSupplierFilterQueryModel,
  UpdatePlatformSupplier,
} from '@mp/supplier-management/common/domain';

@Injectable()
export class PlatformSupplierService {
  private readonly baseApiPath = '/api/contentmanagement/suppliers/platformsuppliers';

  constructor(private readonly httpClient: HttpClient) {}

  getAll(request?: PlatformSupplierFilterQueryModel): Observable<PageResponse<PlatformSupplierBasic>> {
    const params = QueryParams.build<PlatformSupplierBasic>();

    if (request?.searchTerm) {
      params.param('searchTerm', request.searchTerm);
    }

    if (request?.supplierIds) {
      params.param('supplierIds', [...request.supplierIds]);
    }

    params
      .withoutPagination(request?.pagination === false)
      .page(request?.page ?? 1)
      .pageSize(request?.pageSize ?? 250)
      .sort('name');

    return this.httpClient.get<PageResponse<PlatformSupplierBasic>>(`${this.baseApiPath}`, {
      params: params.toHttpParams(),
    });
  }

  get(id: string): Observable<PlatformSupplier> {
    return this.httpClient.get<PlatformSupplier>(`${this.baseApiPath}/${id}`);
  }

  update(id: string, dto: UpdatePlatformSupplier): Observable<PlatformSupplier> {
    return this.httpClient.put<PlatformSupplier>(`${this.baseApiPath}/${id}`, dto);
  }

  exportAll(): Observable<void> {
    return this.httpClient.post<void>(`${this.baseApiPath}/export`, {});
  }
}
